//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-924:_340,_28,_268,_180,_720,_752,_764,_240;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-924')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-924', "_340,_28,_268,_180,_720,_752,_764,_240");
        }
      }catch (ex) {
        console.error(ex);
      }