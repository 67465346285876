
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedItemsRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                return _createElement('li', { 'className': 'grid-item' }, _createElement('div', { 'className': 'card card-product' }, _createElement('figure', { 'className': 'card-media' + (this.variant_ids.length > 1 ? ' multiple-variants' : '') }, _createElement('a', { 'href': this.url }, _createElement('img', {
                    'className': 'card-media-image',
                    'src': this.imageOrDefault(this.resizeImage(this.image)),
                    'alt': this.removeHTML(this.title),
                    'onError': this.onImageError,
                    'width': '190',
                    'height': '200',
                    'loading': 'lazy'
                })), _createElement('div', { 'className': 'card-badge top left' }, this.on_sale ? _createElement('span', {
                    'className': 'badge badge-sale',
                    'key': '461'
                }, 'Sale') : null)), _createElement('div', { 'className': 'card-info' }, _createElement('div', { 'className': 'card-eyebrow' }, _createElement('a', Object.assign({}, {
                    'href': this.vendor_url,
                    'title': this.removeHTML(this.vendor)
                }, { dangerouslySetInnerHTML: { __html: this.vendor } }))), _createElement('h3', { 'className': 'card-heading typography-body' }, _createElement('a', Object.assign({}, { 'href': this.url }, { dangerouslySetInnerHTML: { __html: this.title } })))), _createElement('div', { 'className': 'card-footer' }, _createElement('div', { 'className': 'card-footer-intro' }, !this.out_of_stock ? _createElement('div', {
                    'className': 'badge-stock badge-stock-in',
                    'key': '1005'
                }, _createElement('span', { 'className': 'badge-stock-dot' }), ' In stock\n        ') : null, this.out_of_stock ? _createElement('div', {
                    'className': 'badge-stock badge-stock-out',
                    'key': '1147'
                }, _createElement('span', { 'className': 'badge-stock-dot' }), ' Out of Stock\n        ') : null), _createElement('div', { 'className': 'price' }, _createElement('div', { 'className': 'price-container' }, !this.on_sale ? _createElement('div', {
                    'className': 'price-regular',
                    'key': '1373'
                }, _createElement('span', { 'className': 'visually-hidden' }, 'Regular price'), _createElement('span', { 'className': 'price-item price-item-regular' }, this.formatPrice(this.price))) : null, this.on_sale ? _createElement('div', {
                    'className': 'price-sale',
                    'key': '1607'
                }, _createElement('s', { 'className': 'price-item price-item-regular' }, this.formatPrice(this.compare_at_price)), _createElement('span', { 'className': 'price-item price-item-sale price-item-last' }, this.formatPrice(this.price))) : null)), this.out_of_stock || this.variant_ids.length === 1 ? _createElement('div', { 'key': '1911' }, _createElement('form', {
                    'method': 'post',
                    'action': '/cart/add',
                    'id': 'product-form-' + this.id,
                    'acceptCharset': 'UTF-8',
                    'className': 'shopify-product-form',
                    'encType': 'multipart/form-data',
                    'noValidate': 'novalidate',
                    'data-type': 'add-to-cart-form'
                }, _createElement('input', {
                    'type': 'hidden',
                    'name': 'form_type',
                    'value': 'product'
                }), _createElement('input', {
                    'type': 'hidden',
                    'name': 'utf8',
                    'value': '\u2713'
                }), _createElement('input', {
                    'type': 'hidden',
                    'name': 'id',
                    'value': this.variant_ids[0]
                }), _createElement('input', {
                    'type': 'hidden',
                    'name': 'quantity',
                    'value': '1'
                }), _createElement('input', {
                    'type': 'hidden',
                    'name': 'product-id',
                    'value': this.id
                }), _createElement('div', { 'className': 'product-actions card-product-actions' }, _createElement('div', { 'className': 'product-actions-add-to-cart' }, _createElement('button', {
                    'type': 'submit',
                    'name': 'add',
                    'className': 'button button-outline button-add-to-cart',
                    'disabled': this.out_of_stock
                }, _createElement('span', {}, !this.out_of_stock ? 'Add to cart' : 'Out of stock')))))) : null, !this.out_of_stock && this.variant_ids.length > 1 ? _createElement('div', {
                    'className': 'product-actions card-product-actions',
                    'key': '2956'
                }, _createElement('div', { 'className': 'product-actions-add-to-cart' }, _createElement('a', {
                    'href': this.url,
                    'className': 'button button-outline js-product-link'
                }, 'Select options'))) : null)));
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_related-products' }, _createElement('div', { 'className': 'cm_related-products-title-container' }, _createElement('h2', { 'className': 'cm_related-products-title' }, 'Related products')), _createElement('div', { 'className': 'scroller' }, _createElement('div', { 'className': 'scroller-inner' }, _createElement.apply(this, [
        'div',
        { 'className': 'grid grid-3-col-tablet grid-4-col-desktop cm_related-products-list cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ]))));
}
        export const componentNames = []