import _difference from 'lodash-es/difference.js';
import _uniq from 'lodash-es/uniq.js';

import { isolatedVerifyFitment } from 'Addons/fitmentSearch/isolatedKeys.ts';
import pageType from 'Addons/search/pageType.ts';
import { dialogClosed } from 'Core/actions/dialog.ts';
import {
  ignoreInexactFields,
  forceVehicleSelection,
  forceVehicleSelectionLater,
  setVehicleDialogSelection,
  vehicleSpecificationNeeded,
} from 'Core/actions/fitmentSearch/index.js';
import { setResponse } from 'Core/actions/response.ts';
import {
  createFilteredFitmentSearchResponseFieldsSelector,
  createFitmentSearchExtraFieldsSelector,
  createFitmentSearchResponseFacetsSelector,
  createFitmentSearchResponseSelectionSelector,
  forceVehicleSelectionLaterSelector,
  isNoVehicleSpecificResultsSelector,
  selectedVehicleSelector,
  createAllBaseFieldsHaveSelectionSelector,
  isVehicleLandingFacetSelectedSelector,
  isVehicleSelectedSelector,
  isVehicleSelectionForcedSelector,
  vehicleInexactSelector,
} from 'Core/selectors/fitmentSearch/index.js';
import fitmentSearchConfig from 'Models/uiConfig/fitmentSearchConfig.js';
import uiConfig from 'Models/uiConfig/uiConfig.js';
import { getVehicleFromUri } from 'Modules/converter/index.js';
import { epicFromHandlers } from '../common.js';

const supportedPageTypes = [pageType.search, pageType.category, pageType.brand, pageType.product];
const supportedKeys = [isolatedVerifyFitment, null];

export default epicFromHandlers({
  [dialogClosed]: ({ state, action: { payload } }) => {
    if (payload === 'vehicle-dialog') {
      if (vehicleInexactSelector(state)) {
        return ignoreInexactFields();
      }

      if (isVehicleSelectionForcedSelector(state)) {
        return forceVehicleSelectionLater();
      }
    }
  },
  [setResponse.type]({
    state,
    action: {
      meta: { isolatedKey, onInit },
    },
  }) {
    let isVehicleSelected = isVehicleSelectedSelector(state);
    const allBaseFieldsHaveSelection = createAllBaseFieldsHaveSelectionSelector(isolatedKey)(state);

    if (getVehicleFromUri().isVehicleNew) {
      if (isolatedKey !== isolatedVerifyFitment) {
        // prevent showing specify dialog with selection from uri
        return;
      } else if (allBaseFieldsHaveSelection) {
        // VerifyFitment: since the vehicle from uri is no automatically selected, check if all base fields are selected
        isVehicleSelected = allBaseFieldsHaveSelection;
      }
    }

    const isNoVehicleSpecificResults = isNoVehicleSpecificResultsSelector(state);
    const isVehicleLandingFacetSelected = isVehicleLandingFacetSelectedSelector(state);

    const isPartialMode =
      fitmentSearchConfig.isVehicleWidgetInPartialMode && uiConfig.pageType !== pageType.product;

    const isReferrerInUrl =
      window.location.pathname.split('/').filter(Boolean).length >= 3 && uiConfig.platform === 'shopify';

    const shouldTrySpecifyVehicle =
      isVehicleSelected &&
      !isNoVehicleSpecificResults &&
      !isVehicleLandingFacetSelected &&
      !isPartialMode &&
      !isReferrerInUrl &&
      supportedPageTypes.includes(uiConfig.pageType) &&
      supportedKeys.includes(isolatedKey);

    if (shouldTrySpecifyVehicle) {
      const responseFields = createFilteredFitmentSearchResponseFieldsSelector(isolatedKey)(state);
      const extraFields = createFitmentSearchExtraFieldsSelector(isolatedKey)(state);
      const extraResponseFields = responseFields.filter((f) => extraFields.includes(f));

      const selectedFields = createFitmentSearchResponseSelectionSelector(isolatedKey)(state).map(
        (sel) => sel.field,
      );

      const ignoredFields =
        isolatedKey === null
          ? selectedVehicleSelector(state)
              .filter((v) => v.term === '__ignored')
              .map((v) => v.field)
          : [];

      const missingFields = _difference(extraResponseFields, _uniq([...selectedFields, ...ignoredFields]));

      if (selectedFields.length && missingFields.length) {
        return vehicleSpecificationNeeded(missingFields, isolatedKey);
      }
    }

    const shouldForceVehicleSelection =
      fitmentSearchConfig.forceVehicleSelection &&
      onInit &&
      !isVehicleSelected &&
      !isNoVehicleSpecificResults &&
      !isVehicleLandingFacetSelected &&
      !isReferrerInUrl &&
      supportedPageTypes.includes(uiConfig.pageType) &&
      isolatedKey === null &&
      !forceVehicleSelectionLaterSelector(state);

    const responseFacets = createFitmentSearchResponseFacetsSelector(isolatedKey)(state);
    const isVehicleSelectedPartially = responseFacets._facets.some((f) => f.selection.length > 0);

    if (shouldForceVehicleSelection && !isVehicleSelectedPartially) {
      return forceVehicleSelection();
    }
  },
  [vehicleSpecificationNeeded.type]: ({ state, action: { meta } }) =>
    !meta.isolatedKey && setVehicleDialogSelection(selectedVehicleSelector(state).selection),
});
